import { UserInfoUpdateApi } from '@/api/mine/UserInfoUpdateApi';
import BaseService from '../base/BaseService';

export class UserInfoUpdateService extends BaseService {
    private api = new UserInfoUpdateApi();
    /**
     * 用户头像、签名更新
     * @param param 
     */
    public editPhoto(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.editPhoto(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            })
        })
    }

}