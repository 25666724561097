import LabelBaseService from '../base/LabelBaseSerivce';

export class LinkApi extends LabelBaseService {
    constructor() {
        super("link");
    }

    public getInfo(param: any): Promise <any> {
        return this.request("", this.rest.METHOD_GET,param);
    }
}