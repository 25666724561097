import { LinkApi } from '@/api/label/LinkApi';

export class LinkSerivce {
    private linkApi = new LinkApi();

    public getInfo(param: any): Promise<any> {
        param.nestedQuery= 'data.id:'+param.nestedQuery
        return new Promise((resolve, reject) => {
            this.linkApi.getInfo(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}