import OrganizationBaseService from '../base/OrganizationBaseService';
/**
 * 用户信息更新
 */
export class UserInfoUpdateApi extends OrganizationBaseService{
    constructor(){
        super("")
    }
    public editPhoto(param:any):Promise<any>{
        const path='department/user/edit-photo';
        return this.request(path,this.rest.METHOD_PUT,param)
    }
}