
import {
  modalController,
  popoverController,
  loadingController,
  toastController,
} from "@ionic/vue";
import { defineComponent, toRefs, ref, reactive } from "vue";
//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import { actionSheetController } from "@ionic/vue";
import { TaskScheduleService } from "@/services/task/TaskScheduleService";
import { FileHelper } from "@/utils/FileHelper";
import CookieHelper from "@/utils/CookieHelper";
import Message from "@/models/message/Message";
import Utils from "@/utils/Utils";
import User from "@/models/task/User";
import { UserInfoUpdateService } from "@/services/mine/UserInfoUpdateService";
import DocService from "@/services/doc/docService";
import { SubFolder, FolderParams } from "@/models/file/Folder";
import { OrganizationService } from "@/services/organization/OrganizationService";
import { SupplierService } from "@/services/organization/SupplierService";
import { LinkSerivce } from "@/services/label/LinkService";
import { SingleFile } from "@/models/file/Storage";

export default defineComponent({
  name: "Edit",
  components: {
    Header,
  },
  data() {
    const docService = new DocService();
    const fileHelper = new FileHelper();
    const user: User = {
      id: "",
      pernr: "",
    };
    const storage: SingleFile = {
      folderId: "",
      systemId: "organization",
      busId: "",
      busTable: "ts_user",
      busTag: "ts_user",
    };
    return {
      UserInfoUpdateService: new UserInfoUpdateService(),
      userInfo: user,
      btnIconRight: ["iconbaocun"],
      btnSizeRight: ["34px"],
      progressFiles: {},
      fileHelper: fileHelper,
      docService: docService,
      messageList: new Array<Message>(0),
      folderId_photo: "",
      folderId_sign: "",
      photosMessage: new Array<any>(),
      defaultPhoto: "", //默认头像
      autographMessage: new Array<any>(),
      defaultAutograph: "", //默认签名
      organizationService: new OrganizationService(),
      supplierService: new SupplierService(),
      linkSerivce: new LinkSerivce(),
      userInfoMore: {
        positionZh: "",
        positionEn: "",
        positionDe: "",
        phoneTwo: "",
        phone: "",
        passNum: "",
        hasHssPhoto: "",
        hasPortraitAllowed: "",
        officeLocal: "",
        supplierId: "",
        supplierName: "",
        description: "",
        mobile: "",
      },
      labelList: new Array(),
      prePath: "/ematrixjct/api",
      storage: storage,
    };
  },
  created() {
    this.initData();
  },

  methods: {
    initData() {
      if (SystemUtils.loginUser.photo)
        SystemUtils.loginUser.photo = this.fileHelper.getPreviewPath(
          SystemUtils.loginUser.photo
        );
      if (SystemUtils.loginUser.autograph)
        SystemUtils.loginUser.autograph = this.fileHelper.getPreviewPath(
          SystemUtils.loginUser.autograph
        );
      this.userInfo = SystemUtils.loginUser;
      if (this.userInfo.pernr != undefined && this.userInfo.pernr != "") {
        this.storage.busId = this.userInfo.id;
        this.folderId_photo = "organization@user-folder-picture-" + this.userInfo.pernr;
        this.folderId_sign = "organization@user-folder-sign-" + this.userInfo.pernr;
        this.initPicture();
        this.getDetail(this.userInfo.pernr);
        //去掉标签
      //  this.getLabels(this.userInfo.pernr);
      }
    },
    initPicture() {
      //头像
      const photo = {
        id: "",
        url: this.defaultPhoto,
        status: "done",
        message: "",
        isGif: false,
        name: "",
        isImage: true,
        documentId: "",
      };
      this.photosMessage.push(photo);
      if (this.userInfo.photo != undefined && this.userInfo.photo != null) {
        this.checkFolderExists(this.folderId_photo, this.photosMessage, "picture");
      }
      return
      //签名
      const autograph = {
        id: "",
        url: this.defaultAutograph,
        status: "done",
        message: "",
        isGif: false,
        name: "",
        isImage: true,
        documentId: "",
      };
      this.autographMessage.push(autograph);
      if (this.userInfo.autograph != undefined && this.userInfo.autograph != null) {
        this.checkFolderExists(this.folderId_sign, this.autographMessage, "sign");
      }
    },
    checkFolderExists(pId: string, files: Array<any>, name: string) {
      this.docService.getFilesById(pId).then(
        (r: any) => {
          if (r) {
            if (r.data && r.data.id == pId) {
              this.loadFolderList(pId, files, name);
            }
          }
        },
        (e: any) => {
          //modify by  gaozhq  2021年4月13日12:54:02  ,返回对象不需要json格式
          let data =e.data?e.data:{httpCode:"0000",code:"0000"};
          if (data.httpCode == "404" && data.code == "40000") {
            const folder: SubFolder = {
              parentId: "e0001",
              name: name,
              busId: this.userInfo.pernr,
              busTable: "ts_user",
              busTag: "ts_user",
            };
            const params: FolderParams = Object.assign(
              { rootId: "0001", id: pId },
              folder
            );
            this.docService.createSubFolder(params).then(
              (id: string) => {
                console.log("创建文件夹成功");
              },
              (e: any) => {
                console.log(JSON.stringify(e));
              }
            );
          }
        }
      );
    },
    loadFolderList(pId: string, files: Array<any>, name: string) {
      const params = {
        folderId: pId,
      };
      this.docService.getFileByFolderId(params).then(
        (r: any) => {
          if (r) {
            if (r.data.documents.length > 0) {
              if (r.data != null && "documents" in r.data) {
                let documents: any = r.data["documents"];
                if (documents && documents instanceof Array) {
                  if (documents.length > 0) {
                    let ele = documents[0];
                    files[0].documentId = ele.id;
                    files[0].id = ele.mainFileId;
                    files[0].url = "";
                    files[0].isGif = ele.previewUrl == "";
                    files[0].name = ele.name;
                    let extName = ele.name.substring(ele.name.lastIndexOf(".") + 1);
                    this.fileHelper.downloadThumb(ele.mainFileId, extName, 3).then(
                      (r: string) => {
                        files[0].url = r;
                      },
                      (e: any) => {}
                    );
                  }
                }
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    hexToRgba(hex: string) {
      return Utils.hexToRgba(hex, 0.1);
    },
    //上传头像
    updatePhoto() {
      this.fileHelper.uploadWay = "common";
      this.fileHelper.uploadProgress = true;
      this.fileHelper.openPhotoFolder().then(
        (result: any) => {
          this.photosMessage[0].url = "";
          this.photosMessage[0].status = "uploading";
          this.photosMessage[0].message = "上传中...";
          this.storage.folderId = this.folderId_photo;
          this.storage.documentId = this.photosMessage[0].documentId;
          this.fileHelper.getStorageFolder(
            this.storage,
            result,
            (response: any) => {
              if (response) {
                if (this.userInfo != null && this.userInfo.hasOwnProperty("photo")) {
                  let photo = this.fileHelper.getRelativePath(
                    response.id,
                    response.objectKey
                  );
                  this.UserInfoUpdateService.editPhoto({
                    userId: this.userInfo.id,
                    pernr: this.userInfo.pernr,
                    photo: this.prePath + photo,
                  }).then((r: any) => {
                    if (r) {
                      Utils.presentToast("修改成功");
                    }
                  });
                  this.photosMessage[0].id = response.id;
                  this.photosMessage[0].url = "";
                  this.photosMessage[0].status = "done";
                  this.photosMessage[0].name = response.name;
                  this.photosMessage[0].isGif = response.previewUrl == "";
                  this.photosMessage[0].message = "上传成功";
                  if (response.documentId != undefined && response.documentId != null) {
                    this.photosMessage[0].documentId = response.documentId;
                  }
                  let extName = response.name.substring(
                    response.name.lastIndexOf(".") + 1
                  );
                  this.fileHelper.downloadThumb(response.id, extName, 3).then(
                    (r: string) => {
                      this.photosMessage[0].url = r;
                      SystemUtils.loginUser.photo = r;
                    },
                    (e: any) => {}
                  );
                }
              }
            },
            (e: any) => {
              this.photosMessage[0].status = "failed";
              this.photosMessage[0].message = "上传失败";
            }
          );
        },
        (e: any) => {
          Utils.presentToastWarning("选择手机相册文件失败");
        }
      );
    },
    //上传签名
    updateAutograph() {
      this.fileHelper.uploadWay = "common";
      this.fileHelper.uploadProgress = true;
      this.fileHelper.openPhotoFolder().then(
        (result: any) => {
          this.autographMessage[0].url = "";
          this.autographMessage[0].status = "uploading";
          this.autographMessage[0].message = "上传中...";
          this.storage.folderId = this.folderId_sign;
          this.storage.documentId = this.autographMessage[0].documentId;
          this.fileHelper.getStorageFolder(
            this.storage,
            result,
            (response: any) => {
              if (response) {
                if (this.userInfo != null && this.userInfo.hasOwnProperty("autograph")) {
                  let autograph = this.fileHelper.getRelativePath(
                    response.id,
                    response.objectKey
                  );
                  this.UserInfoUpdateService.editPhoto({
                    userId: this.userInfo.id,
                    pernr: this.userInfo.pernr,
                    autograph: this.prePath + autograph,
                  }).then((r: any) => {
                    if (r) {
                      Utils.presentToast("修改成功");
                    }
                  });
                  this.autographMessage[0].id = response.id;
                  this.autographMessage[0].url = "";
                  this.autographMessage[0].status = "done";
                  this.autographMessage[0].name = response.name;
                  this.autographMessage[0].isGif = response.previewUrl == "";
                  this.autographMessage[0].message = "上传成功";
                  if (response.documentId != undefined && response.documentId != null) {
                    this.autographMessage[0].documentId = response.documentId;
                  }
                  let extName = response.name.substring(
                    response.name.lastIndexOf(".") + 1
                  );
                  this.fileHelper.downloadThumb(response.id, extName, 3).then(
                    (r: string) => {
                      this.autographMessage[0].url = r;
                      SystemUtils.loginUser.autograph = r;
                    },
                    (e: any) => {}
                  );
                }
              }
            },
            (e: any) => {
              this.autographMessage[0].status = "failed";
              this.autographMessage[0].message = "上传失败";
            }
          );
        },
        (e: any) => {
          Utils.presentToastWarning("选择手机相册文件失败");
        }
      );
    },

    //原图预览
    bindPreviewImage(ev: any) {
      if (ev) {
        if (ev.id != "") {
          let extName = ev.name.substring(ev.name.lastIndexOf(".") + 1);
          this.fileHelper.downloadOriginal(ev.id, extName, 3).then(
            (r: any) => {
              this.fileHelper.previewVant([r], 0);
            },
            (e: any) => {}
          );
        }
      }
    },
    getDetail(userId: any) {
      let params = {
        userId: userId,
      };
      this.organizationService.getUserInfo(params).then((result: any) => {
        let res = result.data;
        this.userInfoMore.positionZh = res.positionZh;
        this.userInfoMore.positionEn = res.positionEn;
        this.userInfoMore.positionDe = res.positionDe;
        this.userInfoMore.phoneTwo = res.phoneTwo;
        this.userInfoMore.phone = res.phone;
        this.userInfoMore.passNum = res.passNum;
        this.userInfoMore.hasHssPhoto = res.hasHssPhoto;
        this.userInfoMore.hasPortraitAllowed = res.hasPortraitAllowed;
        this.userInfoMore.officeLocal = res.officeLocal;
        this.userInfoMore.description = res.description;
        this.userInfoMore.supplierId = res.supplierId;
        this.userInfoMore.mobile = res.mobile;

        if (
          this.userInfoMore.supplierId != undefined &&
          this.userInfoMore.supplierId != ""
        ) {
          this.getSupplyInfo();
        }
      });
    },
    getLabels(userId: any) {
      let that = this;
      let params = {
        groupName: "用户分组",
        busId: "user@user-tag-" + userId,
        busTable: "tt_user",
      };
      that.linkSerivce.getInfo(params).then((result: any) => {
        if (result && result.data && result.data.length > 0) {
          that.labelList = result.data;
        }
      });
    },
    getSupplyInfo() {
      let that = this;
      let params = {
        deleteFlag: 0,
        pageNum: 1,
        pageSize: 10000,
      };
      that.supplierService.getList(params).then((result: any) => {
        if (result && result.data && result.data.rows && result.data.rows.length > 0) {
          let list = result.data.rows.filter((ele: any) => {
            return ele.id == that.userInfoMore.supplierId;
          });
          if (list.length > 0) {
            that.userInfoMore.supplierName = list[0].nameZh;
          }
        }
      });
    },
  },
});
