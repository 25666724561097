import { SupplierApi } from '@/api/organization/SupplierApi'

/**
 *机构service
 */
export class SupplierService {

    private supplierApi = new SupplierApi();
    
    //获取信息
    public getList(params:any): any {
        return new Promise((resolve, reject) => {
            this.supplierApi.getList(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}