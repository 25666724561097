/**
 *组织中台 api
 */
import OrganizationBaseService from '../base/OrganizationBaseService';
/**
 * 供应商
 */
export class SupplierApi extends OrganizationBaseService {

    constructor() {
        super("supplier");
    }
   
     //获取信息
    public getList(params: any): Promise<any> {
        const path = 'page';
        return this.request(path,this.rest.METHOD_GET, params);
     }
}